import Proj1 from "../assets/proj1.jpg"
import Proj2 from "../assets/proj2.jpg"
import Proj3 from "../assets/proj4.png"
import Proj4 from "../assets/kodegram.jpg"

export const ProjectList = [

  
  {
    name:"Food Website",
    image:Proj3,
    skills: "Html,Css,Js",
    link:"https://johnpaulladao-github-io-a55j-6r7cvury1-johnpaulladao.vercel.app/"
  },
  {
    name:"KodeGram | Capstone Group Project",
    image:Proj4,
    skills: "Express.js, React.js, Node.js, Bootstrap, MongoDb",
    link:"https://kodegram.devph.space/"
  },
]
