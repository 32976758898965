import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import "react-vertical-timeline-component/style.min.css"
import SchoolIcon from "@material-ui/icons/School"

const Experience = () => {
  return (
    <div className='experience'>
    <VerticalTimeline lineColor='#3e497a'>

    <VerticalTimelineElement 
      className='vertical-timeline--education'
       date='2006-2012'
       iconStyle={{background: "#3e497a",color: "#fff"}}
       icon={<SchoolIcon />}
       >
        <h3 className='vertical-timeline-element-title'>
          My Elementary,San Marcelino Elementary School, San Marcelino Zambales
          <p>Elementary Diploma</p>
        </h3>
       </VerticalTimelineElement>

      <VerticalTimelineElement 
      className='vertical-timeline--education'
       date='2012-2018'
       iconStyle={{background: "#3e497a",color: "#fff"}}
       icon={<SchoolIcon />}
       >
        <h3 className='vertical-timeline-element-title'>
          My High School,Castillejos National High School, Castillejos Zambales
          <p>High School Diploma</p>
        </h3>
       </VerticalTimelineElement>

       <VerticalTimelineElement 
      className='vertical-timeline--education'
       date='2018-2022'
       iconStyle={{background: "#3e497a",color: "#fff"}}
       icon={<SchoolIcon />}
       >
        <h3 className='vertical-timeline-element-title'>
          My College,Kolehiyo Ng Subic, Subic Zambales
          <p>Computer Science</p>
          
        </h3>
       </VerticalTimelineElement>

       <VerticalTimelineElement 
      className='vertical-timeline--education'
       date='december 2022 - march 2023'
       iconStyle={{background: "#3e497a",color: "#fff"}}
       icon={<SchoolIcon />}
       >
        <h3 className='vertical-timeline-element-title'>
           Training ,Kodego BoothCamp, Vertual Training
          <p>Web Development</p>
         
        </h3>
       </VerticalTimelineElement>
    </VerticalTimeline>
    </div>
  )
}

export default Experience