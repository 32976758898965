import React from 'react'
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { Link } from 'react-router-dom';
import "../styles/Footer.css"


const Footer = () => {
  return (
    <div className='footer'>
      <div className='socialMedia'>
        <TwitterIcon />
        <FacebookIcon />
        <LinkedInIcon />
      </div>
      <p> ©2023 John Paul Ladao</p>
    </div>
  )
}

export default Footer