import React from 'react'
import { useParams } from 'react-router-dom'
import { ProjectList } from '../helpers/ProjectList.js'
import GitHubIcon from '@material-ui/icons/GitHub'
import "../styles/ProjectDisplay.css"


const ProjectDisplay = () => {
  const { id } = useParams()
  const project = ProjectList[id]
  console.log(project)

  return (
    <div className='project'>
      <h1> {project.name} </h1>
      <img src={project.image} />
      <p>
        <b>Skills:</b>{project.skills}
      </p>

      <p>
        <a href={project.link} target="_blank"> Live link </a>
      </p>
      <GitHubIcon />
    </div>
  )
}

export default ProjectDisplay
