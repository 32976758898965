import React from 'react'
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import GithubIcon from "@material-ui/icons/GitHub";
import "../styles/Home.css"
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className='home'>
      <div className="about">
        <h2>Hi, My Name is John Paul</h2>
        <div className="prompt">
          <p>
          A Web Developer with a passion for learning and creating
          </p>
          <Link target='_blank' to='https://www.linkedin.com/in/johnpaul-ladao-22138326a/'
          ><LinkedInIcon />
          </Link>

          <Link  to='mailto:johnpaulladao106@gmail.com'>
            <EmailIcon />
          </Link>
          
          <Link target='_blank' to='https://github.com/johnpaulladao'>
            <GithubIcon />
          </Link>

         
        </div>
      </div>
      <div className="skills">

        <h1>Skills</h1>
        <ol className='list'>
          <li className='item'>
            <h2> Front End </h2>
            <span> Html,Css,Bootsrap, Sass,React </span>
          </li>
          <li className='item'>
            <h2> Back-End </h2>
            <span> NodeJs,MySql,MongoDb </span>
          </li>
          <li className='item'>
            <h2> Languages </h2>
            <span> JavaScript </span>
          </li>
        </ol>
      </div>
    </div>
  )
}

export default Home